import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { WINDOW_NAVIGATOR_LANGUAGES } from '@lib/window';
import { AuthService } from '@bafood/auth';
import { LocaleService } from '@shared/services/locale.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  isExpanded = false;
  public readonly userRoles$: Observable<string[]> = this.authService.currentUserRoles$;
  public readonly cmsAccess$: Observable<boolean> = this.authService.cmsAccess$;
  public readonly omsAccess$: Observable<boolean> = this.authService.omsAccess$;
  public readonly lsAccess$: Observable<boolean> = this.authService.lsAccess$;
  public readonly dsAccess$: Observable<boolean> = this.authService.dsAccess$;
  public readonly logisticAccess$: Observable<boolean> = this.authService.logisticAccess$;
  public readonly crmAccess$: Observable<boolean> = this.authService.crmAccess$;
  public readonly integrationConfigAccess$: Observable<boolean> = this.authService.integrationConfigAccess$;
  public readonly language: BehaviorSubject<string> = new BehaviorSubject<string>('lang');

  constructor(
    private readonly authService: AuthService,
    private readonly locale: LocaleService,
    private readonly transloco: TranslocoService,
    @Inject(WINDOW_NAVIGATOR_LANGUAGES) private browserLanguages: NavigatorLanguage['languages'],
  ) { }

  public ngOnInit(): void {
    this._initializeLocale()
  }

  toggle(){
    this.isExpanded = !this.isExpanded;
  }

  public logout(): void {
    this.authService.logout();
  }

  public switchLanguageHandler(lang: string): void {
    this.locale.setLocale(lang);
  }

  private _initializeLocale(): void {
    const defaultLang = this.transloco.getDefaultLang();
    const browserLang = this.browserLanguages.find((lang: string) =>
      this.transloco.isLang(lang),
    );
    let storedLanguage = this.locale.getCurrentLocale();

    if (storedLanguage && !this.transloco.isLang(storedLanguage)) {
      storedLanguage = null;
    }

    const language = storedLanguage ?? browserLang ?? defaultLang ?? 'en';
    this.locale.setLocale(language);
    this.language.next(language);
  }
}
