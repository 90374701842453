import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocalStorageService } from '@bafood/shared';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './keycloak-initializer';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [],
  imports: [KeycloakAngularModule],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: initializer,
        multi: true,
        deps: [KeycloakService, LocalStorageService]
    },
    AuthService
  ]
})
export class AuthConfigModule { }