import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerModule } from '@bafood/libs/spinner';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgxsModule } from '@ngxs/store';

import { environment } from 'src/environments/environment';

import { AppStore } from '../store';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { ERROR_INTERCEPTOR_PROVIDER } from './interceptors/error/provider';
import { KEYCLOAK_INTERCEPTOR_PROVIDER } from './interceptors/keycloak/provider';
import { REQUEST_INTERCEPTOR_PROVIDER } from './interceptors/request/provider';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot([AppStore], {
      developmentMode: !environment.production,
    }),
    TranslocoRootModule,
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-US',
        ru: 'ru-RU',
        pl: 'pl-PL',
        uk: 'uk-UA',
      },
    }),
    SpinnerModule,
  ],
  providers: [
    ERROR_INTERCEPTOR_PROVIDER,
    KEYCLOAK_INTERCEPTOR_PROVIDER,
    REQUEST_INTERCEPTOR_PROVIDER,
  ],
  exports: [
    BrowserAnimationsModule,
    TranslocoRootModule,
    TranslocoLocaleModule,
    SpinnerModule,
  ],
})
export class CoreModule {}
