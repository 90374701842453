import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { MakeHttpContextToken } from './MakeHttpContextToken';


export const HttpRequestWithoutCacheContext = new MakeHttpContextToken(false);

@Injectable()
export class HttpRequestWithoutCacheInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!request.headers.get('Cache-Control')) {
      const withoutCache = HttpRequestWithoutCacheContext.getContext(request.context);
      if (withoutCache) {
        request = request.clone({headers: request.headers.set('Cache-Control', 'no-cache')})
      }
    }
    return next.handle(request)
  }
}
