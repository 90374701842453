import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export type HttpOptions = {
  headers: HttpHeaders | { [header: string]: string | string[] };
  context: HttpContext;
  params: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  withCredentials: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  protected readonly debug = false;

  constructor(private _http: HttpClient) {}

  public get<R>(path: string, options?: Partial<HttpOptions>): Observable<R> {
    if (this.debug) {
      console.log('ORIGINAL GET ', path, options);
    }
    return this._http.get<R>(path, options);
  }

  public post<R, T extends object = never>(path: string, data: T, options?: Partial<HttpOptions>): Observable<R> {
    if (this.debug) {
      console.log('ORIGINAL POST ', path, data, options);
    }
    return this._http.post<R>(path, data, options);
  }

  public put<R, T extends object = never>(path: string, data: T, options?: Partial<HttpOptions>): Observable<R> {
    if (this.debug) {
      console.log('ORIGINAL PUT ', path, data, options);
    }
    return this._http.put<R>(path, data, options);
  }

  public patch<R, T extends object = never>(path: string, data: T, options?: Partial<HttpOptions>): Observable<R> {
    if (this.debug) {
      console.log('ORIGINAL PATH ', path, data, options);
    }
    return this._http.patch<R>(path, data, options);
  }

  public delete<R>(path: string, options?: Partial<HttpOptions>): Observable<R> {
    if (this.debug) {
      console.log('ORIGINAL DELETE ', path, options);
    }
    return this._http.delete<R>(path, options)
  }
}
