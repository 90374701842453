import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderLanguageComponent } from './header-language.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

const MATERIAL = [
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
]

@NgModule({
  declarations: [HeaderLanguageComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    MATERIAL,
  ],
  exports: [HeaderLanguageComponent],
})
export class HeaderLanguageModule { }
