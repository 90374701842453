import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { MakeHttpContextToken } from './MakeHttpContextToken';

export const HTTP_REQUEST_TIMEOUT = new InjectionToken<number>('HTTP_REQUEST_TIMEOUT');

export const HttpRequestTimeoutContext = new MakeHttpContextToken<number>(0);

//https://stackoverflow.com/questions/45938931/default-and-specific-request-timeout
//https://netbasal.com/new-in-angular-v12-passing-context-to-http-interceptors-308a1ca2f3dd

@Injectable()
export class HttpRequestTimeoutInterceptor implements HttpInterceptor {

  constructor(@Optional() @Inject(HTTP_REQUEST_TIMEOUT) protected defaultTimeout: number) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = HttpRequestTimeoutContext.getContext(request.context) || this.defaultTimeout;
    if (timeoutValue) {
      const timeoutValueNumeric = Number(timeoutValue);
      return next.handle(request).pipe(
        timeout({
          first: timeoutValueNumeric,
          each: timeoutValueNumeric,
          with: () => EMPTY
        }),
        catchError((error) => {
          if (!(error instanceof TimeoutError)) {
            return throwError(error);
          }
          return EMPTY;
        })
      );
    }
    return next.handle(request)
  }
}
