import { AuthRolesEnum } from '../../auth/enums/auth-roles.enum';

export const ALLOWED_ROLES = [
  AuthRolesEnum.CRM_ADMIN,
  AuthRolesEnum.CRM_MANAGER,
  AuthRolesEnum.LOYALTY_ADMIN,
  AuthRolesEnum.LOYALTY_MANAGER,
  AuthRolesEnum.DELIVERY_ADMIN,
  AuthRolesEnum.DELIVERY_MANAGER,
  AuthRolesEnum.OMS_ADMIN,
  AuthRolesEnum.OMS_MANAGER,
  AuthRolesEnum.CMS_ADMIN,
];
