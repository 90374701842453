import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Lang } from '../../conts';
import { environment } from 'environment';
import { HttpRequestWithoutCacheContext } from '@bafood/interceptors/HttpRequestWithoutCache.interceptor';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private _http: HttpClient) { }

  public getTranslation(lang: Lang): Observable<Translation> {
    return this._http.get<Translation>(`/assets/i18n/${lang}.json?${environment.applicationVersion}`);
  }
}
