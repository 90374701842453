/* eslint-disable no-undef */

/* eslint-disable no-console */
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { SpinnerService } from '@bafood/libs/spinner';

import { IErrorResponse } from '../../interfaces/error-response.interface';
import { StatusCodes } from './status-codes.enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _spinnerService: SpinnerService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this._spinnerService.stop();

        if (error.status !== StatusCodes.INTERNAL_SERVER_ERROR) {
          this._checkError(error);

          return throwError(error);
        }

        return of(error.error);
      }),
    );
  }

  private _onNotFoundError(url: string | null): void {
    console.warn('Not found error', url);
  }

  private _onInternalServerError(error: IErrorResponse): void {
    console.error('Internal server error: ', error);
  }

  private _onUnknownError(url: string | null): void {
    console.warn('Unknown Error: ', url);
  }

  private _onBadRequestError(error: IErrorResponse): void {
    console.error('Bad request error: ', error);
  }

  private _checkError(error: HttpErrorResponse): void {
    const url = error.url;

    switch (error.status) {
      case StatusCodes.INTERNAL_SERVER_ERROR:
        this._onInternalServerError(error.error);
        break;
      case StatusCodes.BAD_REQUEST:
        this._onBadRequestError(error.error);
        break;
      case StatusCodes.NOT_FOUND:
        this._onNotFoundError(url);
        break;
      default:
        this._onUnknownError(url);
    }
  }
}
