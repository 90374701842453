import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _active$ = new BehaviorSubject<boolean>(false);
  private _disabled$ = new BehaviorSubject<boolean>(false);
  private _disabledUrls$ = new BehaviorSubject<Array<string|RegExp>>([]);

  public get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  public get disabled$(): Observable<boolean> {
    return this._disabled$.asObservable();
  }

  public get disabled(): boolean {
    return this._disabled$.value;
  }

  public get disabledUrls(): Array<string|RegExp> {
    return this._disabledUrls$.value;
  }

  constructor() {}

  public start(): void {
    if (!this._active$.value) {
      this._active$.next(true);
    }
  }

  public stop(): void {
    if (this._active$.value) {
      this._active$.next(false);
    }
  }

  public disable(...urls: Array<string|RegExp>): void {
    if ((urls?.length ?? 0) == 0) {
      this._disabled$.next(true);
    }
    this._disabledUrls$.next(urls);
  }

  public enable(): void {
    this._disabled$.next(false);
  }
}
