import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { ApiGatewayInterceptor } from './api-gateway.interceptor';
import { LocaleService } from '@shared/services/locale.service';
import { AuthService } from '@bafood/auth';
import { LocalStorageService } from '@bafood/shared';

@Injectable({
  providedIn: 'root'
})

export class LocaleHeadersInterceptor extends ApiGatewayInterceptor implements ApiGatewayInterceptor {
  constructor(
    private readonly _localStorageService: LocalStorageService,
    private _locale: LocaleService,
    private _auth: AuthService
  ) {
    super();
  }

  protected _intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this._locale.lang) {
      request = request.clone({headers: request.headers.set('X-Context-Language', this._locale.lang)});
    }
  
    return next.handle(request);
  }
}
