import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@bafood/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SentryProviders } from 'src/sentry';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocaleHeadersInterceptor } from './interceptors/locale-headers.interceptor';
import { HttpRequestWithoutCacheInterceptor } from './interceptors/HttpRequestWithoutCache.interceptor';
import { HttpRequestTimeoutInterceptor, HTTP_REQUEST_TIMEOUT } from './interceptors/HttpRequestTimeout.interceptor';
import { APP_REFRESH_CHUNK_LOAD_PROVIDER } from '@lib/hardRouter';
import { APP_VERSION } from '@lib/app-tokens';
import { environment } from 'environment';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MainContainerComponent } from './main/container/main-container/main-container.component';
import { AuthConfigModule } from './auth/auth.config.module';
import { SvgUtilModule } from '@shared/components/svg-util/svg-util.module';
import { HeaderLanguageModule } from '@shared/components/header-language';
import { SharedModule } from './shared/shared.module';

const maskConfig: Partial<IConfig> = {
  validation: true
};


const MATERIAL = [
  MatSidenavModule,
  MatButtonModule,
  MatTooltipModule,
  MatListModule,
  MatIconModule,
];

@NgModule({
  declarations: [
    AppComponent,
    MainContainerComponent
  ],
  imports: [
    CoreModule,
    AuthConfigModule,
    SvgUtilModule,
    HeaderLanguageModule,
    SharedModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(maskConfig),
    MATERIAL,
  ],
  providers: [
    { provide: APP_VERSION, useValue: environment.applicationVersion },
    ...SentryProviders,
    APP_REFRESH_CHUNK_LOAD_PROVIDER,
    { provide: HTTP_REQUEST_TIMEOUT, useValue: 30000 },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestWithoutCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestTimeoutInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
