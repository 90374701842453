import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { TranslocoService } from '@ngneat/transloco';

import { ISnackbarConfig } from './../interfaces/config.interface';

@Injectable()
export class SnackbarService {
  private _config: ISnackbarConfig = {
    duration: 4000,
  };

  private _successMessage = '';
  private _okButttonMessage = '';
  private _errorMessage = '';


  snackBarRef!: MatSnackBarRef<any>;
  snackBarList: { message: string }[] = []

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _transloco: TranslocoService,
  ) {
    this._initDefaultSnackbarMessages();
  }

  private next() {
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.snackBarList.splice(0, 1);
      if (this.snackBarList.length) {
        this.snackBarRef = this._snackBar.open(
          this.snackBarList[0].message,
          this._okButttonMessage,
          this._config,
        );
      }
    });
  }

  public setSuccessMesssage(
    successMesssageKey: string,
    needTranslate = true,
  ): this {
    if (needTranslate) {
      this._successMessage =
        this._transloco.translate<string>(successMesssageKey);
    } else {
      this._successMessage = successMesssageKey;
    }

    return this;
  }

  public setOkButtonLabel(
    okButtonLabekKey: string,
    needTranslate = true,
  ): void {
    if (needTranslate) {
      this._okButttonMessage =
        this._transloco.translate<string>(okButtonLabekKey);
    } else {
      this._okButttonMessage = okButtonLabekKey;
    }
  }

  public setErrorMessage(errorMessageKey: string, needTranslate = true): void {
    if (needTranslate) {
      this._errorMessage = this._transloco.translate<string>(errorMessageKey);
    } else {
      this._errorMessage = errorMessageKey;
    }
  }

  public success(message?: string): void {
    if (!this.snackBarList.length) {
      this.snackBarRef = this._snackBar.open(
        message ?? this._successMessage,
        this._okButttonMessage,
        this._config,
      );
    }
    this.snackBarList.push({
      message: message ?? this._successMessage
    })
    this.next();
  }

  public error(message?: string): void {
    if (!this.snackBarList.length) {
      this.snackBarRef = this._snackBar.open(
        message ?? this._errorMessage,
        this._okButttonMessage,
        this._config,
      );
    }
    this.snackBarList.push({
      message: message ?? this._errorMessage
    })
    this.next();
  }

  private _initDefaultSnackbarMessages(): void {
    this._successMessage = this._transloco.translate<string>(
      'SNACKBAR.SAVED_SUCCESSFULLY',
    );

    this._okButttonMessage = this._transloco.translate<string>(
      'BUTTONS.OK',
    );

    this._errorMessage = this._transloco.translate<string>(
      'SNACKBAR.SAVE_FAILURE',
    );
  }
}
