import * as Sentry from '@sentry/browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { LocalStorageService, CREDENTIALS_KEY } from '@bafood/shared';

import { AuthRolesEnum } from '../enums/auth-roles.enum';
import { IToken } from '../interfaces/credentials.interface';
import { ITokenInfo } from '../interfaces/token-info.interface';
import { HttpService } from '@bafood/core/repositories/http.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public currentUserRolesSubject = new BehaviorSubject<string[]>([]);
  private readonly jwtHelper = new JwtHelperService();

  private _tokenInfo$ = new BehaviorSubject<ITokenInfo>(null!);
  public get tokenInfo(): ITokenInfo {
    return this._tokenInfo$.value!;
  }

  public readonly currentUserRoles$ =
    this.currentUserRolesSubject.asObservable();

  public get roles$(): Observable<string[]> {
    return of(Object.values(AuthRolesEnum));
  }

  public readonly crmAccess$ = this.currentUserRoles$.pipe(map(this.checkCRMAccess), shareReplay(1));

  public readonly logisticAccess$ = this.currentUserRoles$.pipe(map(this.checkLogisticAccess), shareReplay(1));

  public readonly cmsAccess$ = this.currentUserRoles$.pipe(map(this.checkCMSAccess), shareReplay(1));

  public readonly omsAccess$ = this.currentUserRoles$.pipe(map(this.checkOMSAccess), shareReplay(1));

  public readonly lsAccess$ = this.currentUserRoles$.pipe(map(this.checkLogisticAccess), shareReplay(1));

  public readonly dsAccess$ = this.currentUserRoles$.pipe(map(this.checkDeliveryAccess), shareReplay(1));

  public readonly integrationConfigAccess$ = this.currentUserRoles$.pipe(map(this.checkIntegrationConfigUpdateAccess), shareReplay(1));

  public readonly cmsPricingUpdate$ = this.currentUserRoles$.pipe(map(this.checkСmsPricingUpdateAccess), shareReplay(1));


  constructor(
    private readonly _localStorageService: LocalStorageService,
    protected readonly http: HttpService,
    private keycloakService: KeycloakService
  ) {}

  public me(): Observable<ITokenInfo> {
    try {
      const { access_token } =
        this._localStorageService.get<IToken>(CREDENTIALS_KEY) || {};

        const tokenInfo = this.jwtHelper.decodeToken<ITokenInfo>(access_token);
      this._tokenInfo$.next(tokenInfo);
      if (!tokenInfo) {
        Sentry.setUser(null);
      }

      Sentry.setUser({
        id: tokenInfo.sub,
        sid: tokenInfo.sid,
        email: tokenInfo.email,
        display: tokenInfo.name,
        username: tokenInfo.preferred_username,
      });


      this.currentUserRolesSubject.next(tokenInfo?.realm_access?.roles);

      return of(tokenInfo);
    } catch (e) {
      return throwError(e);
    }
  }

  public logout(): void {
    this.currentUserRolesSubject.next([]);
    this._localStorageService.remove(CREDENTIALS_KEY);
    this.keycloakService.logout(window.location.origin)
  }

  private checkCRMAccess(roles: string[]): boolean {
    const accessToCrm =
      roles.includes(AuthRolesEnum.CRM_ADMIN) ||
      roles.includes(AuthRolesEnum.CRM_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToCrm;
  }

  private checkLogisticAccess(roles: string[]): boolean {
    const accessToCrm =
      roles.includes(AuthRolesEnum.DELIVERY_ADMIN) ||
      roles.includes(AuthRolesEnum.DELIVERY_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToCrm;
  }

  private checkLoyaltyAccess(roles: string[]): boolean {
    const accessToLoyalty =
      roles.includes(AuthRolesEnum.LOYALTY_ADMIN) ||
      roles.includes(AuthRolesEnum.LOYALTY_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToLoyalty;
  }

  private checkDeliveryAccess(roles: string[]): boolean {
    const accessToDelivery =
      roles.includes(AuthRolesEnum.DELIVERY_ADMIN) ||
      roles.includes(AuthRolesEnum.DELIVERY_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToDelivery;
  }

  private checkOMSAccess(roles: string[]): boolean {
    const accessToOms =
      roles.includes(AuthRolesEnum.OMS_ADMIN) ||
      roles.includes(AuthRolesEnum.OMS_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToOms;
  }

  private checkCMSAccess(roles: string[]): boolean {
    const accessToCMS =
      roles.includes(AuthRolesEnum.OMS_ADMIN) ||
      roles.includes(AuthRolesEnum.OMS_MANAGER) ||
      roles.includes(AuthRolesEnum.SUPER_ADMIN);

    return accessToCMS;
  }

  private checkIntegrationConfigUpdateAccess(roles: string[]): boolean {
    const accessToCMS = roles.includes(AuthRolesEnum.INTEGRATION_CONFIG_UPDATE);

    return accessToCMS;
  }

  private checkСmsPricingUpdateAccess(roles: string[]): boolean {
    const accessToCMS = roles.includes(AuthRolesEnum.CMS_PRICING_UPDATE);

    return accessToCMS;
  }
}
