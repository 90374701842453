import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, StaticProvider } from '@angular/core';
import { Router } from '@angular/router';
import { extractError } from '@lib/error-handler/extract-error';

export class AppSentryErrorHandler extends SentryErrorHandler {
  excludeError(error: unknown): boolean {
    return error instanceof HttpErrorResponse && [HttpStatusCode.NotFound, HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)
  }

  handleError(error: unknown): void {
    if (this.excludeError(error)) {
      if (this._options.logErrors) {
        console.error(error);
      }
      return;
    }
    return super.handleError(error);
  };

  constructor(options?: ErrorHandlerOptions){
    super(options);
  }
}

export let SentryProviders: StaticProvider[] = [
  {
    provide: ErrorHandler,
    useValue: new AppSentryErrorHandler({
      showDialog: false,
      extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
        return defaultExtractor(extractError(error));
      }
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => Promise.resolve(),
    deps: [Sentry.TraceService],
    multi: true,
  }
];

export function initSentryBrowser(environment: any){
  if (environment.sentryBrowserEnable) {
    Sentry.init({
      environment: environment.production ? "prod" : 'dev',
      release: environment.applicationVersion,
      dsn: environment.sentryDsn,
      integrations: [
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: true
        }),
        new Integrations.BrowserTracing({
          tracingOrigins: [environment.sentryTracingOrigins ?? /^\//],
          tracePropagationTargets: [environment.tracePropagationTargets],
          routingInstrumentation: Sentry.routingInstrumentation
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  } else {
    console.warn('sentry disabled');
    SentryProviders = [];
  }
}
