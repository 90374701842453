import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MainContainerComponent } from './main/container/main-container/main-container.component';

const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'partners',
        pathMatch: 'full',
      },
      {
        path: 'partners',
        loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
      },
      {
        path: 'no-access',
        loadChildren: () => import('./no-access-page/no-access-page.module').then((m) => m.NoAccessPageModule),
      },
    ]
  },
  
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
