export enum AuthRolesEnum {
  SUPER_ADMIN = 'super_admin',
  // todo: remove above
  KITCHEN_CREATE = 'delivery.kitchen.create',
  CRM_ADMIN = 'crm_admin',
  CRM_MANAGER = 'crm_manager',
  CMS_PRICING_UPDATE = 'cms.pricing.update',
  CMS_BRANDS_EDIT = 'cms.brand.admin',
  CMS_ADMIN = 'cms_admin',
  LOYALTY_ADMIN = 'loyalty_admin',
  LOYALTY_MANAGER = 'loyalty_manager',
  DELIVERY_ADMIN = 'delivery_admin',
  DELIVERY_MANAGER = 'delivery_manager',
  OMS_ADMIN = 'oms_admin',
  OMS_MANAGER = 'oms_manager',
  KDS_ADMIN = 'kds_admin',
  INTEGRATION_CONFIG_UPDATE = 'integration.config.update',
  COURIER = 'courier',
  STATION_DEVICE = 'station_device',
  FS_ADMIN = 'fs.admin',
  PARTNER_ADMIN = 'partner_admin'
}
