import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthService } from '@bafood/auth';
import { LocalStorageService } from '@bafood/shared';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly _authService: AuthService,
    private readonly _localStorageService: LocalStorageService,
    private _router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivateChild(route, state);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const allowedRoles = (route.data.allowedRoles || []) as string[];
    // eslint-disable-next-line no-undef
    const redirectUrl = btoa(state.url);
    const queryParams: Params = { redirectUrl };

    return this._authService.me().pipe(
      
      map((tokenInfo) => {
        if (!this._authService.currentUserRolesSubject.value?.length) {
          this._authService.currentUserRolesSubject.next(tokenInfo?.realm_access?.roles);
        }

        return tokenInfo?.realm_access?.roles;
      }),
      map((roles) => {
        const someRoles = allowedRoles.some((allowed) =>
          roles?.includes(allowed),
        );
        return someRoles;
      }),
      tap((canActivate) => {
        if (!canActivate) {
          void this._router.navigate(['/no-access'], { queryParams });
        }
      }),
      catchError(() => this._router.navigate(['/no-access'], { queryParams })),
    );
  }
}
