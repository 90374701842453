/* eslint-disable no-console */

/* eslint-disable no-undef */
import { Inject, Injectable } from '@angular/core';
import { WINDOW_LOCAL_STORAGE } from '@lib/window';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor(@Inject(WINDOW_LOCAL_STORAGE) private localStorage: Storage) {}

  public set<T>(key: string, value: T): void {
    try {
      this.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(`Cannot set item with key '${key}'`, e);
    }
  }

  public get<T>(key: string): T | null {
    try {
      return JSON.parse(this.localStorage.getItem(key) as string) as T | null;
    } catch (e) {
      return null;
    }
  }

  public remove(key: string): void {
    try {
      this.localStorage.removeItem(key);
    } catch (e) {
      console.error(`Cannot remove item with key '${key}'`, e);
    }
  }
}
