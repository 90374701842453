import { HttpContext, HttpContextToken } from '@angular/common/http';

export class MakeHttpContextToken<T> {

  private readonly token: HttpContextToken<T>;

  constructor(defaultValue?: T) {
    this.token = new HttpContextToken<T>(() => defaultValue!);
  }

  public setContext(context: HttpContext, value: T): HttpContext {
    return context.set(this.token, value);
  }

  public getContext(context: HttpContext): T {
    return context.get(this.token);
  }
}
