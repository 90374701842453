import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { TRANSLOCO_HTTP_LOADER_CONFIG_PROVIDER } from './services/transloco-http-loader/transloco-http-loader-config.provider';
import { TRASLOCO_HTTP_LOADER_PROVIDER } from './services/transloco-http-loader/transloco-http-loader.provider';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    TRANSLOCO_HTTP_LOADER_CONFIG_PROVIDER,
    TRASLOCO_HTTP_LOADER_PROVIDER,
  ],
})
export class TranslocoRootModule {}
