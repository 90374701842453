import { CREDENTIALS_KEY, LocalStorageService } from '@bafood/shared';
import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializer(keycloak: KeycloakService, localStorageService: LocalStorageService): () => Promise<boolean> {
  keycloak.keycloakEvents$.subscribe(res => {
    if (res.type === KeycloakEventType.OnAuthSuccess) {
      keycloak.getToken().then(token => {
        localStorageService.set(CREDENTIALS_KEY, { access_token: token });
      });
    } else if (res.type === KeycloakEventType.OnAuthRefreshSuccess) {
      keycloak.getToken().then(token => {
        localStorageService.set(CREDENTIALS_KEY, { access_token: token });
      });
    } else if (res.type === KeycloakEventType.OnTokenExpired) {
      keycloak.updateToken(20);
    } else if (res.type === KeycloakEventType.OnAuthRefreshError) {
      keycloak.logout();
    } else if (res.type === KeycloakEventType.OnAuthLogout) {
      localStorageService.remove(CREDENTIALS_KEY);
    }
  })
  const options: KeycloakOptions = {
    config: environment.keycloak,
    initOptions: {
      onLoad: 'check-sso',
      redirectUri: window.location.href,
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    },
  };
  

  return () => keycloak.init(options)
}
