import { Observable } from 'rxjs';

import { Component } from '@angular/core';

import { SpinnerService } from './services/spinner.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  public active$!: Observable<boolean>;

  constructor(private readonly _spinnerService: SpinnerService) {
    this.active$ = this._spinnerService.active$.pipe(debounceTime(500));
  }
}
