import { Injectable } from '@angular/core';

import { State } from '@ngxs/store';

import { PaginationState } from './pagination';
import { ProductState } from './product';

@State<{
  pagination: PaginationState;
  product: ProductState;
}>({
  name: 'appstore',
  defaults: {
    pagination: {},
    product: {},
  },
})
@Injectable()
export class AppStore {}
