import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { SpinnerService } from '@bafood/libs/spinner';
import { environment } from 'environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private _totalRequests = 0;

  constructor(private readonly _spinnerService: SpinnerService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isSpinnerDisabled = this._spinnerService.disabled
    const disabledUrls = this._spinnerService.disabledUrls;

    if (!isSpinnerDisabled && disabledUrls.findIndex((disabledUrl) => {
      if (typeof disabledUrl == 'string') {
        return disabledUrl === request.url;
      }
      return disabledUrl.test(request.url);
    }) == -1) {
      this._totalRequests++;
      this._spinnerService.start();
    }

    let updateRequest: HttpRequest<any>;
    if (request.url.startsWith(environment.API_GATEWAY_URL)) {
      const headers = request.headers
        .set('X-App-Platform', 'adm');
  
      updateRequest = request.clone({
        headers,
      });
    } else {
      updateRequest = request;
    }
  
    return next.handle(updateRequest).pipe(
      finalize(() => {
        if (!isSpinnerDisabled && this._totalRequests > 0) {
          this._totalRequests--;
        }

        if (!this._totalRequests) {
          this._spinnerService.stop();
        }
      }),
    );
  }
}
