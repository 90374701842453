import { Lang } from '../../conts';
import { TRANSLOCO_CONFIG, translocoConfig } from '@ngneat/transloco';
import { environment } from 'environment';

export const TRANSLOCO_HTTP_LOADER_CONFIG_PROVIDER = {
  provide: TRANSLOCO_CONFIG,
  useValue: translocoConfig({
    availableLangs: Object.values(Lang),
    defaultLang: Lang.en,
    fallbackLang: Lang.en,
    missingHandler: {
      useFallbackTranslation: true
    },
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production,
  }),
};
