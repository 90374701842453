import { appEnvironment } from './app.environment';

export const environment = {
  "API_GATEWAY_URL": "https://api.development.modaapp.co",
  "sentryDsn": String(appEnvironment.sentry || '').trim(),
  "sentryTracingOrigins": "partner.development.modaapp.co",
  "tracePropagationTargets": "api.development.modaapp.co",
  "production": false,
  "sentryBrowserEnable": true,
  "applicationVersion": String(appEnvironment.version),
  "LOAD_FILE_URL": "https://files.development.modaapp.co",
  "ADMIN_PANEL_URL": "https://admin.development.modaapp.co",
  "PUSHER_AUTH_KEY": "changeme",
  "keycloak": {
    "url": "https://keycloak.development.cloud.modaapp.co",
    "realm": "moda",
    "clientId": "admin-admin-ui"
  }
};
