import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environment';

export abstract class ApiGatewayInterceptor implements HttpInterceptor {

  protected readonly apiGateWayUrl: string = environment.API_GATEWAY_URL;

  protected abstract _intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.apiGateWayUrl) && this._intercept) {
      return this._intercept(req, next);
    }
    return next.handle(req);
  }
}
