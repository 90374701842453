import { NgModule } from '@angular/core';
import { SvgUtilComponent } from './svg-util.component';

@NgModule({
  declarations: [
    SvgUtilComponent
  ],
  imports: [],
  exports: [
    SvgUtilComponent,
  ],
})
export class SvgUtilModule { }
