import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackbarService } from './services/snackbar.service';

@NgModule({
  declarations: [],
  providers: [SnackbarService],
  imports: [CommonModule, MatSnackBarModule],
})
export class SnackbarModule {}
